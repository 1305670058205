
import { mapState, mapGetters } from 'vuex'
import playGames from '~/mixins/common/play-games'
export default {
    name: 'MenubarComponent',
    mixins: [playGames],
    data: () => ({
        openCard: [],
        v: true,
    }),
    computed: {
        ...mapState({
            menu: (state) => state.settings.menu, // old api
        }),
        ...mapGetters({
            menubars: 'cms/menubars',
        }),
    },
}
